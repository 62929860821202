html, body {
  scroll-behavior: smooth;
  margin: 0;
}

header, .introduction-container {
  background: #96c3eb;
}

ul {
  padding-inline-start: 0;
}

li {
  list-style-type: none;
}

a {
  color: #293845;
  text-decoration: none;
}

.navbar-home {
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  padding: 1.5rem;
  display: grid;
}

.btn11 {
  display: none;
}

.main-one {
  justify-content: space-evenly;
  display: flex;
}

.main-one a {
  font-size: 2rem;
}

.logo-navbar {
  width: 300px;
}

.introduction-container {
  justify-content: space-around;
  display: flex;
}

.title-first-section {
  color: #293845;
  font-size: 2.7rem;
}

.article-container {
  margin-top: 4.5rem;
  padding: 2rem;
}

.btn-input-top {
  text-transform: uppercase;
  cursor: pointer;
  min-width: 230px;
  background-color: #fff;
  border: none;
  border-radius: .2rem;
  margin-top: 8rem;
  padding: 1.5rem;
  font-size: 1.2rem;
}

.first-input {
  color: #4596dd;
}

.second-input {
  color: #ac6362;
  margin-left: 3rem;
}

.why-choose-us-container {
  margin-top: 5rem;
}

.first-party {
  justify-content: space-between;
  margin-bottom: 2rem;
  display: flex;
}

.first-party-h2 {
  color: #293845;
  font-size: 2.7rem;
}

.first-party-h2:after {
  content: "";
  max-width: 700px;
  border-bottom: 2px solid #ced6dc;
  margin: .2rem auto;
  display: block;
}

.first-para {
  max-width: 1000px;
  color: #293845;
  margin: auto auto 7rem;
  font-size: 2rem;
  line-height: 1.7;
}

.vector-secondSection svg {
  width: 400px;
  height: auto;
}

.statistics-container {
  background: #8dd7cf;
}

.title-three-section {
  text-align: center;
  color: #293845;
  padding-top: 5rem;
  padding-bottom: 3rem;
  font-size: 2.7rem;
  font-weight: 400;
}

.title-three-section:after {
  content: "";
  max-width: 550px;
  border-bottom: 1px solid #788796;
  margin: 1.5rem auto;
  display: block;
}

.article-container-three {
  justify-content: space-evenly;
  align-items: center;
  display: flex;
}

.article-container-three :last-child {
  margin-bottom: 2rem;
}

.title-article-three {
  max-width: 30rem;
  font-size: 2.5rem;
}

.title-four-section {
  text-align: center;
  color: #2a3947;
  background: #96c3eb;
  margin: 0;
  padding-top: 3rem;
  font-size: 2.7rem;
}

.container-four {
  background: #96c3eb;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-evenly;
  padding: 3rem 6rem;
  display: grid;
}

.article-four-section {
  max-width: 450px;
  margin: 0 auto;
}

.border {
  content: "";
  max-width: 650px;
  border-right: 2px solid #9eadba;
  margin: 0 auto;
  display: block;
}

.second-article-section {
  align-items: center;
  display: flex;
}

.second-article-section > p {
  color: #293845;
  padding: 1rem;
  font-size: xx-large;
}

.article-four-section > p:last-child {
  margin-bottom: 3rem;
}

.btn-input-devis {
  color: #fff;
  cursor: pointer;
  width: 600px;
  background-color: #1bae9f;
  border: none;
  border-radius: .2rem;
  padding: 2.7rem;
  font-size: 1.5rem;
}

.btn-devis {
  text-align: center;
  background: #96c3eb;
  padding: 5rem;
}

.container-five {
  max-width: 1600px;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  margin: 0 auto;
  display: grid;
}

.container-five > figure:last-child {
  margin-bottom: 5rem;
}

.title-five-section {
  text-align: center;
  color: #293845;
  margin-bottom: 5rem;
  padding-top: 3rem;
  font-size: 2rem;
}

.our-realisations-container {
  height: 1000px;
  background: #8dd7cf;
}

.title-six-section {
  text-align: center;
  color: #293845;
  padding-top: 5rem;
  font-size: 2rem;
}

.first-container-six {
  max-width: 1400px;
  grid-template-columns: repeat(4, 1fr);
  place-items: center;
  margin: 4rem auto;
  display: grid;
}

.first-container-six + .second-container-six > figure {
  margin: 0;
}

.second-container-six {
  max-width: 1250px;
  grid-template-columns: repeat(2, 1fr);
  place-items: center;
  margin: 0 auto;
  display: grid;
}

.three-container-six {
  max-width: 300px;
  margin: 3rem auto;
}

.title-section-seven {
  text-align: center;
  color: #293845;
  margin: 2rem;
  padding: 1rem;
  font-size: 3rem;
}

.last-para-seven {
  font-weight: bold;
}

.contact-section-seven {
  text-align: center;
  padding-bottom: .5rem;
  font-size: 3rem;
}

.contact-section-seven:before {
  content: "";
  text-align: center;
  max-width: 1200px;
  border-bottom: 3px solid #ced6dc;
  margin: 5rem auto;
  display: block;
}

.last-title-section-seven {
  max-width: 750px;
  margin: 0 auto;
  font-size: 2rem;
}

.container-seven-one {
  max-width: 1600px;
  grid-template-columns: repeat(auto-fill, minmax(25%, 25%));
  gap: 11%;
  margin: 0 auto;
  display: grid;
}

.ul-packages {
  width: 250px;
  height: 350px;
  border: 1px solid #2a3947;
  border-radius: 60% / 40%;
  align-items: center;
  margin: 2rem;
  padding: 2rem;
  display: grid;
}

.red {
  max-width: 250px;
  max-height: 280px;
  justify-content: center;
  display: grid;
}

.red p {
  max-width: 200px;
  margin: 0;
  line-height: 1.5rem;
}

.title-package, .tarif-package {
  max-width: max-content;
  text-align: center;
  font-size: 1.4rem;
}

.title-package:after, .tarif-package:after {
  content: "";
  border-bottom: 1px solid #788796;
  margin: 1rem;
  display: block;
}

.para-details {
  max-width: 150px;
  margin: 0 auto;
}

.button-contact-us {
  text-align: center;
}

.contact-button-last {
  color: #fff;
  cursor: pointer;
  background: #8cd7ce;
  border: none;
  margin: 6rem;
  padding: 2.5rem 15rem;
  font-size: 1.7rem;
}

.container {
  grid-gap: 5%;
  background: #96c3eb;
  grid-template-columns: repeat(4, 1fr);
  display: grid;
}

.container > figure {
  align-content: center;
  display: grid;
}

.footer-contact-link {
  align-items: center;
  display: flex;
}

.icon-sociaux-footer {
  width: 2.5rem;
}

.text-contact-link li {
  font-size: 1.5rem;
}

.footer-title {
  max-width: max-content;
  font-size: 1.5rem;
}

.footer-title:after {
  content: "";
  text-align: center;
  border-bottom: 2px solid #ced6dc;
  margin: .7rem auto;
  display: block;
}

.footer-section-link > a > span > li {
  line-height: 3rem;
}

.footer-section-link-last a > span > li {
  line-height: 2.5rem;
}

@media only screen and (max-width: 650px) {
  .navbar-home {
    height: 2rem;
    align-items: center;
    display: block;
  }

  .navbar-home .logo-navbar {
    width: 240px;
    margin: 0;
    position: absolute;
  }

  .main-navbar {
    width: 100%;
    height: 250%;
    z-index: 1;
    background: #96c3ea;
    margin-top: 3.5rem;
    transition: all .4s ease-out;
    position: absolute;
    left: -650px;
  }

  .main-one {
    height: 25%;
    flex-direction: column;
    align-items: center;
    line-height: 1.5;
  }

  .main-navbar li:hover {
    background: #6b99bf;
  }

  .main-navbar.visible {
    left: 0;
  }

  .ligneUnique {
    width: 40px;
    height: 4px;
    cursor: pointer;
    background: #fff;
    border-radius: 5px;
    transition: all 0s .3s;
    position: absolute;
    top: 40px;
    right: 30px;
  }

  .btn11 {
    display: block;
  }

  .btn11.active .ligneUnique {
    background: #fff0;
  }

  .ligneUnique:before {
    content: "";
    width: 100%;
    height: 4px;
    background: #fff;
    border-radius: 5px;
    transition: bottom .3s .3s, transform .3s;
    position: absolute;
    bottom: 11px;
  }

  .ligneUnique:after {
    content: "";
    width: 100%;
    height: 4px;
    background: #fff;
    border-radius: 5px;
    transition: top .3s .3s, transform .3s;
    position: absolute;
    top: 11px;
  }

  .btn11.active .ligneUnique:after {
    transition: top .3s, transform .3s .3s;
    top: 0;
    transform: rotate(45deg);
  }

  .btn11.active .ligneUnique:before {
    transition: bottom .3s, transform .3s .3s;
    bottom: 0;
    transform: rotate(-45deg);
  }

  .introduction-container {
    display: block;
  }

  .title-first-section {
    font-size: 1.5rem;
  }

  .article-container {
    margin-top: -2rem;
    padding: 2rem;
  }

  .btn-input-top {
    min-width: 180px;
    margin-top: 1.5rem;
    display: block;
  }

  .second-input {
    color: #ac6362;
    margin-left: 0;
  }

  .section-container > figure {
    text-align: center;
    margin-inline-start: 0;
    padding: 1.5rem;
  }

  .vectorOne svg {
    width: 320px;
    height: auto;
  }

  .container-two {
    margin-top: 3rem;
  }

  .vector-secondSection svg {
    width: 320px;
    height: auto;
  }

  .first-party {
    margin-bottom: 2rem;
    display: block;
  }

  .first-party figure {
    text-align: center;
  }

  .first-party-h2 {
    color: #293845;
    padding: 1.5rem;
    font-size: 1.5rem;
  }

  .first-para {
    margin-bottom: 3rem;
    padding: 1.5rem;
    font-size: 1.4rem;
    line-height: 1.5;
  }

  .container-three {
    padding: 1rem;
  }

  .title-three-section {
    text-align: center;
    max-width: 300px;
    margin: 0 auto;
    padding-top: 3rem;
    padding-bottom: 0;
    font-size: 1.5rem;
  }

  .title-three-section:after, .separate-line-third-section:after {
    content: "";
    max-width: 300px;
    border-bottom: 1px solid #788796;
    display: block;
  }

  .article-container-three {
    margin-bottom: 1rem;
    display: block;
  }

  .article-container-three figure {
    text-align: center;
  }

  .article-container-three svg {
    width: 320px;
    height: auto;
  }

  .article-container-three:after {
    content: "";
    max-width: 300px;
    margin: 0 auto;
    display: block;
  }

  .article-container-three > figure > img {
    width: 300px;
  }

  .title-article-three {
    text-align: center;
    padding: 1.5rem;
    font-size: 1.5rem;
  }

  .title-four-section {
    text-align: center;
    color: #2a3947;
    background: #96c3eb;
    margin: -1px;
    font-size: 1.5rem;
  }

  .container-four {
    background: #96c3eb;
    padding: 2rem;
    display: block;
  }

  .article-four-section {
    margin: 0;
  }

  .border {
    content: "";
    border-bottom: 2px solid #9eadba;
    border-right: none;
    display: block;
  }

  .btn-devis {
    background: #96c3eb;
    padding: 0 1rem 2.5rem;
  }

  .btn-input-devis {
    color: #fff;
    cursor: pointer;
    width: auto;
    background-color: #1bae9f;
    border: none;
    border-radius: .2rem;
    padding: 1.3rem;
  }

  .title-five-section {
    text-align: center;
    color: #2a3947;
    margin-bottom: 2rem;
    padding: 1.5rem;
    font-size: 1.6rem;
  }

  .container-five {
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    display: grid;
  }

  .container-five > figure > img {
    width: 100px;
  }

  .container-five > figure:last-child {
    margin-bottom: 0;
  }

  .logoSectionFive svg {
    width: 100px;
    height: auto;
  }

  .title-six-section {
    text-align: center;
    color: #2a3947;
    margin-bottom: 2.5rem;
    padding-top: 2rem;
    font-size: 1.9rem;
  }

  .container-main-six {
    height: auto;
  }

  .first-container-six {
    grid-template-columns: repeat(2, 1fr);
    margin: 0;
    display: grid;
  }

  .first-container-six svg, .second-container-six svg {
    width: 120px;
  }

  .three-container-six {
    text-align: center;
    margin: 0 auto;
  }

  .three-container-six svg {
    width: 150px;
    text-align: center;
  }

  .title-section-seven {
    text-align: center;
    padding: .5rem;
    font-size: 1.6rem;
  }

  .last-para-seven {
    font-weight: bold;
  }

  .contact-section-seven {
    margin: 0;
    padding: 0 1.5rem;
    font-size: 1.5rem;
  }

  .last-title-section-seven {
    padding: 1.5rem;
    font-size: 1.2rem;
  }

  .container-seven-one {
    grid-template-columns: repeat(1, 1fr);
    gap: 0;
    padding: 1.5rem;
    display: grid;
  }

  .ul-packages {
    margin: 1.5rem auto;
  }

  .title-package:after {
    min-width: 170px;
  }

  .contact-section-seven:before {
    content: "";
    border-bottom: 1px solid #ced6dc;
    margin: 2rem auto;
    display: block;
  }

  .button-contact-us {
    text-align: center;
  }

  .contact-button-last {
    color: #fff;
    cursor: pointer;
    background: #8cd7ce;
    border: none;
    margin: 2.5rem;
    padding: 1.5rem 3rem;
    font-size: 1rem;
  }

  footer .container > figure {
    width: 250px;
    margin: 0 auto;
  }

  footer .container > article {
    margin: 2.5rem 1rem;
  }

  .footer-section-link > a > span > li {
    min-width: 280px;
  }

  .icon-sociaux-footer, .icon-sociaux-contact {
    margin-right: 7px;
  }
}

@media only screen and (max-width: 360px) {
  .navbar-home .logo-navbar {
    width: 200px;
  }

  .btn-input-top {
    padding: 1rem;
  }

  .vectorOne svg, .vector-secondSection svg, .article-container-three svg {
    width: 250px;
  }

  .second-article-section > p {
    font-size: 1.3rem;
  }

  .second-article-section > span > svg {
    width: 35px;
  }

  .btn-input-devis {
    padding: 1rem;
  }

  .logoSectionFive svg, .first-container-six svg, .second-container-six svg {
    width: 80px;
  }

  .three-container-six svg {
    width: 100px;
    height: auto;
  }

  .ul-packages {
    width: 200px;
    height: 250px;
  }

  footer .container > article {
    margin: 2rem 1rem;
  }

  footer .container > figure {
    width: 200px;
    margin: 0 auto;
  }

  footer .icon-sociaux-contact svg, footer .icon-sociaux-footer svg {
    width: 25px;
  }

  footer .text-contact-link li {
    font-size: 1.2rem;
  }
}

/*# sourceMappingURL=contact.9bc59963.css.map */
